import React from 'react';
import Blog from './Blog';
import { graphql } from 'gatsby';
import Footer from '../components/Shared/Footer';
import NMDSINavbar from '../components/nmdsi/NMDSINavbar';

const Index = ({ data }) => {
  let isNMDSI = data?.strapiPosts?.websites[0]?.id !== 1;

  return (
    <>
      <NMDSINavbar />
      <div className="mx-auto max-w-7xl">
        <Blog data={data} isNMDSI={isNMDSI} />
      </div>
      <Footer />
    </>
  );
};

export default Index;

export const postQuery = graphql`
  query ($slug: String!) {
    strapiPosts(slug: { eq: $slug }) {
      title
      slug
      excerpt
      content
      date
      authors {
        name
        title
        picture {
          url
        }
      }
      categories {
        name
      }
      coverImage {
        url
      }
      websites {
        id
      }
    }
    allStrapiPosts(sort: { order: DESC, fields: date }) {
      edges {
        node {
          slug
          date
          image {
            url
          }
          title
          categories {
            name
          }
          websites {
            name
            id
          }
        }
      }
    }
  }
`;
