import React from 'react';
import { Link } from 'gatsby';

const Sidebar = ({ data, isNMDSI, category, pubDate }) => {
  const allPost = data.allStrapiPosts;
  const postPubDate = new Date(pubDate);

  //get all articles that are in the same category
  let articlesInCategory = allPost.edges.filter((x) => x?.node?.categories[0]?.name === category);
  //get articles that are older than the current post
  let nextArticles = articlesInCategory.filter((x) => new Date(x?.node?.date) < postPubDate);
  //if there are less than 3 older articles, begin showing the newest articles again
  let articles = [...new Set([...nextArticles, ...articlesInCategory])];

  let sidebarContent = articles.slice(0, 3).map((item, i) => (
    <Link
      key={i}
      to={`/blog/${item.node.slug}`}
      className="w-full px-4 text-lg text-center text-white capitalize transition-all cursor-pointer hover:text-yellow"
    >
      <div className="flex flex-col justify-center w-full mb-16">
        <div
          className="mx-auto mb-5 bg-center bg-cover w-72 h-72 md:w-full "
          style={{ backgroundImage: `url(${item?.node?.image[0]?.url})` }}
        />
        <span>{item.node.title}</span>
      </div>
    </Link>
  ));

  return (
    <div className="h-full py-10 lg:px-10 bg-blue">
      <h1 className="pb-10 text-6xl text-center text-white uppercase sm:text-4xl">MORE FROM {category}</h1>
      <div className="flex flex-col items-center justify-center w-full mb-16 space-y-6">{sidebarContent}</div>
      <div className="flex justify-center">
        <Link
          to={isNMDSI ? '/blog' : '/blog'}
          className="px-12 py-2 text-lg capitalize transition-all border cursor-pointer border-yellow text-yellow hover:bg-yellow hover:text-blue"
        >
          View All Articles
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
