import React from 'react';
import Sidebar from './Sidebar';
import { AiOutlineRight } from '@react-icons/all-files/ai/AiOutlineRight';
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import Header from './Header';

const StyledContent = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  p {
    margin: 1.5rem 0;
  }

  h1,
  h2 {
    font-family: 'Tandelle-Regular';
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0e497b;
    margin: 1.25rem 0;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  ul {
    list-style: disc outside none;
  }

  ol {
    list-style: decimal outside none;
  }

  li {
    margin-left: 1rem;
  }

  blockquote {
    font-style: italic;
    margin-left: 0.75rem;
    border-left: 0.5rem solid #ccc;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .ql-size-small {
    font-size: 0.75rem;
  }

  .ql-size-large {
    font-size: 1.5rem;
  }
`;

const Blog = ({ data, isNMDSI }) => {
  const post = data.strapiPosts;
  const pubdate = new Date(post.date);
  const pubdateformat = pubdate.toLocaleDateString('en-us', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
  });

  return (
    <div className="w-full ">
      <div className="mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6">
          <div className="md:col-span-2 lg:col-span-4 ">
            {data?.strapiPosts?.coverImage?.url && <Header data={data} />}
            <div className="flex mt-6 mb-8 ml-5">
              <div className="flex">
                <Link to="/" className="text-sm text-grey md:self-center">
                  Home
                </Link>
                <span className="flex md:self-center">
                  <AiOutlineRight className="mx-1 mt-1 text-sm text-grey md:mx-2" />
                </span>
              </div>
              <div className="flex">
                <Link to="/blog" className="text-sm text-grey md:self-center">
                  Blog
                </Link>
                <span className="flex md:self-center">
                  <AiOutlineRight className="mx-1 mt-1 text-sm text-grey md:mx-2" />
                </span>
              </div>
              <div className="flex">
                <h3 className="text-sm text-grey md:self-center">
                  {post?.categories[0]?.name === undefined ? '' : post?.categories[0]?.name}
                </h3>
              </div>
            </div>
            <div className="px-5 mt-6 mb-8 ">
              <h1 className="mb-8 text-3xl leading-10 text-blue md:text-5xl">{post?.title}</h1>
              <div className="flex mb-6">
                <div className="mr-4">
                  <div
                    className="w-16 h-16 mb-3 overflow-hidden bg-center bg-cover border-2 rounded-full border-blue"
                    style={{ backgroundImage: `url(${post?.authors[0]?.picture[0].url})` }}
                    alt={`${post?.authors[0]?.name}`}
                  ></div>
                </div>
                <div className="flex flex-col py-3">
                  <h3 className="text-sm capitalize text-lightBlue">
                    {post?.authors[0]?.title ? `${post?.authors[0]?.name},` : post?.authors[0]?.name}{' '}
                    {post?.authors[0]?.title ? `${post?.authors[0]?.title}` : ''}
                  </h3>
                  <span className="flex text-xs text-brown">{pubdateformat}</span>
                </div>
              </div>
              <StyledContent
                dangerouslySetInnerHTML={{ __html: post.content.replace(/<p><br[\/]?><[\/]?p>/g, '') }}
                className="text-xl absolutemx-auto text-brown"
              ></StyledContent>
              <div className="flex items-center mt-20 cursor-pointer">
                <BiChevronLeft className="flex text-3xl text-blue md:self-center" />
                <span
                  onClick={() => navigate(-1)}
                  className=" text-blue hover:text-yellow capitalize text-base font-semibold mt-0.5 "
                >
                  View All Articles
                </span>
              </div>
            </div>
          </div>
          <div className="md:col-span-2 lg:col-span-2">
            <Sidebar data={data} isNMDSI={isNMDSI} category={post?.categories[0]?.name} pubDate={post.date} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
